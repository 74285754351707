import { Container } from 'components/Container';
import { InformationBanner } from 'pages/brand-hub/BrandHubOverviewPage';
import { BrandHubInformationTab } from 'pages/brand-hub/informationTab';
import styled from 'styled-components';
export function KnowledgePage() {
  return (
    <Root>
      <InformationBanner />
      <BrandHubInformationTab />
    </Root>
  );
}

const Root = styled(Container).attrs({ size: 'lg' })`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xmedium};
`;
