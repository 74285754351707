import { OpenInNew } from '@mui/icons-material';
import { ListItemButtonProps, Stack, Tooltip, Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FlexContainer from 'components/FlexContainer';
import { SideBarData } from 'config/routes';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { useSidebarStore } from 'features/pageSidebar/useSidebarStore';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled, { css, DefaultTheme, keyframes } from 'styled-components';
import { withTestId } from 'utils/utils';

type BaseProps = {
  name?: IntlMessageKeys;
  children?: ReactNode;
  icon: ReactNode;
  to?: string;
  target?: string;
  id?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  isActive?: boolean;
  tooltip?: IntlMessageKeys;
  gtmId?: string;
  ListItemButtonProps?: ListItemButtonProps;
  showHoverIcon?: boolean;
  subRoutes?: SideBarData[];
};

type PropsWithName = BaseProps & {
  name: IntlMessageKeys;
  children?: never;
};

type PropsWithChildren = BaseProps & {
  name?: never;
  children: ReactNode;
};

type Props = PropsWithName | PropsWithChildren;

export const SidebarItem = ({
  id,
  name,
  icon,
  isActive,
  tooltip,
  to,
  target,
  gtmId,
  onClick,
  children,
  ListItemButtonProps,
  showHoverIcon,
  subRoutes
}: Props) => {
  const isSidebarExpanded = useSidebarStore(state => state.isExpanded);
  const customerName = useAppSelector(state => state.customer.username);

  const renderedContent = (
    <>
      <SidebarItemIcon icon={icon} />

      <StyledListItemText $isSidebarExtended={isSidebarExpanded}>
        {children ?? (
          <SidebarItemText variant="body2">
            <FormattedMessage id={name} />
          </SidebarItemText>
        )}
      </StyledListItemText>
      {showHoverIcon && (
        <StyledListItemIcon>
          <OpenInNew fontSize="small" className="openInNew" />
        </StyledListItemIcon>
      )}
    </>
  );

  const renderedButton = to ? (
    <StyledListItemButton
      {...withGtmInteraction(gtmId)}
      component={UnstyledNavigationLink}
      {...withTestId('sideBarButton')}
      to={to}
      target={target}
      onClick={onClick}
      $isActive={isActive}
      {...ListItemButtonProps}
    >
      {renderedContent}
    </StyledListItemButton>
  ) : (
    <StyledListItemButton {...withGtmInteraction(gtmId)} onClick={onClick} {...ListItemButtonProps}>
      {renderedContent}
    </StyledListItemButton>
  );

  const renderedButtonWithTooltip = tooltip ? (
    <Tooltip
      disableHoverListener={isSidebarExpanded}
      title={
        <FormattedMessage
          id={tooltip}
          values={{
            customer: customerName
          }}
        />
      }
      placement="right"
    >
      {renderedButton}
    </Tooltip>
  ) : (
    renderedButton
  );

  const isMarkedAsActive = subRoutes && subRoutes.length > 0 ? false : isActive;

  return (
    <>
      <Root gap="small" id={id} $isActive={isMarkedAsActive}>
        <ListItem disablePadding={true} sx={{ display: 'block' }}>
          {renderedButtonWithTooltip}
        </ListItem>
      </Root>
      {isSidebarExpanded && subRoutes && subRoutes.length > 0 && (
        <SubmenuStack>
          {subRoutes?.map(subRoute => (
            <Root key={subRoute.path}>
              <ListItem disablePadding={true}>
                <StyledListItemButton to={subRoute.path} component={UnstyledNavigationLink}>
                  <SidebarItemText
                    variant="body2"
                    $color={subRoute.path === location.pathname ? 'blue__500main' : undefined}
                  >
                    <FormattedMessage id={subRoute.title} />
                  </SidebarItemText>
                </StyledListItemButton>
              </ListItem>
            </Root>
          ))}
        </SubmenuStack>
      )}
    </>
  );
};

const SidebarItemIcon = ({ icon }: { icon: ReactNode }) => (
  <StyledListItemIcon
    sx={{
      minWidth: 0,
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    {icon}
  </StyledListItemIcon>
);

const SubmenuStack = styled(Stack)`
  margin-left: 35px;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  width: 24px;
  height: 24px;
`;

const StyledListItemButton = styled(ListItemButton)`
  height: ${({ theme }) => theme.spacings.five};
  padding-block: 0;
  // To prevent jumping while open/closing the sidebar, we don't center the elements
  // To create a centered look, we add a padding to the left of the list item
  padding-left: 12px;
  margin-bottom: ${({ theme }) => theme.spacings.one};
  // Maintain consistent border radius to avoid visual jump when hover state is removed
  border-radius: ${({ theme }) => theme.borderRadius.one};

  .openInNew {
    visibility: hidden;
  }

  &:hover {
    background-color: unset;

    .openInNew {
      visibility: visible;
    }
  }
` as typeof ListItemButton;

export const SidebarItemText = styled(Typography)<{
  $color?: keyof DefaultTheme['colors']['palette'];
}>`
  && {
    color: ${({ $color, theme }) =>
      $color ? theme.colors.palette[$color] : theme.colors.textSecondary};
  }
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Root = styled(FlexContainer)<{ $isActive?: boolean }>`
  justify-content: center;
  align-items: center;

  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.one};
  transition: ${({ theme }) => ` background-color ${theme.transition.default}`};

  &:hover {
    ${StyledListItemIcon} {
      svg {
        color: ${({ theme }) => theme.colors.primaryColorMain};
        fill: ${({ theme }) => theme.colors.primaryColorMain};
      }
    }

    ${SidebarItemText} {
      color: ${({ theme }) => theme.colors.primaryColorMain};
      font-weight: bold;
    }
  }

  ${({ $isActive, theme }) =>
    $isActive
      ? css`
          ${StyledListItemIcon} {
            &,
            > svg {
              color: ${theme.colors.primary500};
              fill: ${theme.colors.primary500};
            }
          }
          ${SidebarItemText} {
            color: ${theme.colors.primaryColorMain};
          }
        `
      : undefined};
`;

const UnstyledNavigationLink = styled(NavLink)`
  color: inherit;
  text-decoration: inherit;
  &:hover {
    text-decoration: none;
  }
`;

const StyledListItemText = styled(ListItemText)<{ $isSidebarExtended: boolean }>`
  opacity: ${({ $isSidebarExtended }) => ($isSidebarExtended ? 1 : 0)};
  ${({ $isSidebarExtended }) => mkSidebarAnimation($isSidebarExtended)}

  margin-left: 12px;
`;

const loadFromLeft = keyframes`
  0% {
    opacity: 0;
    clip-path: inset(0 100% 0 0);
  }
  100% {
    opacity: 1;
    clip-path: inset(0 0 0 0);
  }
`;

const unloadToLeft = keyframes`
  0% {
    opacity: 1;
    clip-path: inset(0 0 0 0);
  }
  100% {
    opacity: 0;
    clip-path: inset(0 100% 0 0);
  }
`;

export const mkSidebarAnimation = ($isSidebarExtended: boolean) => css`
  animation: ${$isSidebarExtended ? loadFromLeft : unloadToLeft} 0.3s forwards;
`;
