import { Container } from 'components/Container';
import { BrandHubWorkflowsTab } from 'pages/brand-hub/workflowsTab';
import styled from 'styled-components';

export function WorkflowsPage() {
  return (
    <Root>
      <BrandHubWorkflowsTab />
    </Root>
  );
}

const Root = styled(Container).attrs({ size: 'lg' })`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xmedium};
`;
