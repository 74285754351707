import { CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { InformationAccordion } from 'features/information/apply-information/InformationAccordion';
import { useGetInformationCategoriesQuery } from 'features/information/CopyPasteView';
import { InformationCategoryDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationCategories';
import {
  GetInformationListParams,
  httpGetInformationList,
  InformationDto
} from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import styled from 'styled-components';
import { withTestId } from 'utils/utils';

type InformationSelectionProps = {
  value: InformationDto[] | undefined;
  searchQuery: string;
  filteredTag: InformationCategoryDto | undefined;
  selectionLimit?: number;
  onChange: (value: InformationDto[]) => void;
};

const NO_CATEGORY: InformationCategoryDto = {
  id: 0,
  name: 'information.apply_modal.co_category',
  customer_id: '',
  alias: ''
};

export function InformationSelection(props: InformationSelectionProps) {
  const { value, filteredTag, searchQuery, selectionLimit, onChange } = props;

  const queryResult = useGetInformationCategoriesQuery({ is_paginated: 0, has_information: 1 });

  const isLoading = queryResult.isLoading;
  const isError = queryResult.isError;

  const isSingleChoiceSelection = selectionLimit === 1;

  const handleSelectInformation = (info: InformationDto) => {
    const selectedInformation = value || [];
    const isSelected = selectedInformation.some(i => i.id === info.id);

    if (isSelected) {
      onChange(selectedInformation.filter(i => i.id !== info.id));
      return;
    }

    if (selectionLimit && selectedInformation.length >= selectionLimit) {
      selectedInformation.shift(); // Remove the first item
    }

    onChange([...selectedInformation, info]);
  };

  const noCategoryFetchParam: GetInformationListParams = {
    category_id: NO_CATEGORY.id
  };

  const { data: noCategoryData } = useQuery({
    queryKey: httpGetInformationList.makeQueryKey(noCategoryFetchParam),
    queryFn: () => httpGetInformationList.callEndpoint(noCategoryFetchParam)
  });

  if (isError) {
    return null;
  }

  if (isLoading) {
    return (
      <CenteredBox>
        <CircularProgress />
      </CenteredBox>
    );
  }

  const tags = queryResult.data.pages.map(page => page.data).flat(1);

  if (!searchQuery && noCategoryData && noCategoryData.total > 0) {
    tags.push(NO_CATEGORY);
  }

  return (
    <InformationBox {...withTestId('information-box')}>
      {tags.length > 0 &&
        tags
          .filter(tag => !filteredTag || tag.id === filteredTag.id)
          .map(tag => (
            <InformationAccordion
              key={tag.id}
              filteredTag={filteredTag}
              searchQuery={searchQuery}
              value={value}
              tag={tag}
              isSingleChoiceSelection={isSingleChoiceSelection}
              handleSelectInformation={handleSelectInformation}
            />
          ))}
    </InformationBox>
  );
}
const InformationBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.three};

  height: 23.25rem;
  overflow-y: scroll;
`;

const CenteredBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
