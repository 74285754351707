import { useModal } from 'components/modals';
import { ModalType } from 'components/modals/modalComponents';
import { ModalProps } from 'components/modals/types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

type ReceivedNavigationMessage = {
  type: 'nav';
  path: string;
};

type ReceivedWindowSizeMessage = {
  type: 'size';
  width: number;
  height: number;
};

type ReceivedModalMessage = {
  type: 'show-modal';
  modalId: ModalType;
  options?: ModalProps;
};

type ReceivedIframeMessage =
  | ReceivedNavigationMessage
  | ReceivedWindowSizeMessage
  | ReceivedModalMessage;

export function useIframeMessageListener() {
  const navigate = useNavigate();
  const { showModal } = useModal();

  useEffect(() => {
    const onEventMessage = (evt: MessageEvent) => {
      // TODO not working on local
      // if (evt.origin !== brandHubIframeUrl) {
      //   return;
      // }

      const data = evt.data as ReceivedIframeMessage;

      if (data.type === 'nav') {
        navigate(data.path);
      }

      if (data.type === 'show-modal') {
        showModal(data.modalId, data?.options);
      }

      if (data.type === 'size') {
        // if needed, set the size of the iframe
      }
    };

    window.addEventListener('message', onEventMessage);
    return () => {
      window.removeEventListener('message', onEventMessage);
    };
  }, [navigate, showModal]);
}
