import { Button, Typography } from '@mui/material';
import leftBanner from 'assets/trial.svg';
import leftBannerTrialEnded from 'assets/trial_ended.png';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { withTestId } from 'utils/utils';

const NUMBER_OF_PERKS = 4;
const XL_BREAKPOINT = 1200;

type BaseTrialModalBodyProps = {
  imageSrc: string;
  imageObjectPosition: string;
  title: ReactNode;
  description?: ReactNode;
  benefitsTitle: ReactNode;
  benefitsPrefix: string;
  mainActionText: ReactNode;
  otherOptionText: ReactNode;
  primaryAction: () => void;
  secondaryAction: () => void;
};

const BaseTrialModalBody = ({
  imageSrc,
  imageObjectPosition,
  title,
  description,
  benefitsTitle,
  benefitsPrefix,
  mainActionText,
  otherOptionText,
  primaryAction,
  secondaryAction,
}: BaseTrialModalBodyProps): ReactElement | null => {
  return (
    <ModalBody>
      <Content>
        <LeftImage src={imageSrc} $objectPosition={imageObjectPosition} />
        <RightSide>
          <LargeHeader>{title}</LargeHeader>
          {description && <Typography>{description}</Typography>}
          <Typography variant="h6">{benefitsTitle}</Typography>

          <ul>
            {Array.from({ length: NUMBER_OF_PERKS }, (_, index) => (
              <li key={index}>
                <strong>
                  <FormattedMessage id={`${benefitsPrefix}_${index}_title`} />
                </strong>{' '}
                <FormattedMessage id={`${benefitsPrefix}_${index}_description`} />
              </li>
            ))}
          </ul>
          <ButtonWrapper>
            <Button fullWidth variant="contained" color="primary" onClick={primaryAction} {...withTestId('start-trial-button')}>
              {mainActionText}
            </Button>
            <CenteredText>
              <OtherOptionLink variant="body2" color="textSecondary" onClick={secondaryAction} {...withTestId('reject-trial-button')}>
                {otherOptionText}
              </OtherOptionLink>
            </CenteredText>
          </ButtonWrapper>
        </RightSide>
      </Content>
    </ModalBody>
  );
};

// Specific component for TrialModalBody
type TrialModalBodyProps = {
  enforceFreeTrial: boolean | null;
  primaryAction: () => void;
  secondaryAction: () => void;
};

export const TrialModalBody = ({
  enforceFreeTrial,
  primaryAction,
  secondaryAction,
}: TrialModalBodyProps): ReactElement | null => {
  return (
    <BaseTrialModalBody
      imageSrc={leftBanner}
      imageObjectPosition="10% 55%"
      title={<FormattedMessage id="trial_modal.title" />}
      description={<FormattedMessage id="trial_modal.description" />}
      benefitsTitle={<FormattedMessage id="trial_modal.benefits_title" />}
      benefitsPrefix="trial_modal.benefits"
      mainActionText={<FormattedMessage id={`trial_modal.${enforceFreeTrial ? 'required' : 'optional'}.main_action_text`} />}
      otherOptionText={<FormattedMessage id={`trial_modal.${enforceFreeTrial ? 'required' : 'optional'}.other_option_text`} />}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
    />
  );
};

// Specific component for TrialEndedModalBody
type TrialEndedModalBodyProps = {
  primaryAction: () => void;
  secondaryAction: () => void;
};

export const TrialEndedModalBody = ({
  primaryAction,
  secondaryAction,
}: TrialEndedModalBodyProps): ReactElement | null => {
  return (
    <BaseTrialModalBody
      imageSrc={leftBannerTrialEnded}
      imageObjectPosition="top"
      title={<FormattedMessage id="trial_ended_modal.title" />}
      benefitsTitle={<FormattedMessage id="trial_ended_modal.benefits_title" />}
      benefitsPrefix="trial_ended_modal.benefits"
      mainActionText={<FormattedMessage id="trial_ended_modal.main_action_text" />}
      otherOptionText={<FormattedMessage id="trial_ended_modal.other_option_text" />}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
    />
  );
};


const ButtonWrapper = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: ${XL_BREAKPOINT}px) {
    height: 100%;
    flex-direction: row;
    gap: 1em;
  }
`;

const LargeHeader = styled(Typography).attrs({ variant: 'h1' })`
  font-size: xxx-large;
`;

const LeftImage = styled.img<{ $objectPosition?: string }>`
  border-radius: 14px 14px 0 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: ${({ $objectPosition }) => $objectPosition};
  aspect-ratio: 3 / 2;

  @media (min-width: ${XL_BREAKPOINT}px) {
    border-radius: 24px 0px 0px 24px;
    width: auto;
    object-fit: unset;
    object-position: unset;
    aspect-ratio: unset;
  }
`;

const ModalBody = styled.div`
  position: relative;
  overflow-y: auto;
  max-height: calc(100vh - 3.5rem);
  padding-right: 0;

  @media (min-width: ${XL_BREAKPOINT}px) {
    padding-right: 1.75rem;
  }
`;

const RightSide = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 32px;

  @media (min-width: ${XL_BREAKPOINT}px) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
`;

const CenteredText = styled.div`
  padding-top: 16px;
  text-align: center;
`;

const OtherOptionLink = styled(Typography)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};

  :hover {
    text-decoration: underline;
  }
`;