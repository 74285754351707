import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import cx from 'classnames';
import { useModal } from 'components/modals';
import LocalStorageKey from 'config/localStorageKey';
import { TrialEndedModalBody, TrialModalBody } from 'features/forceTrial/TrialModalBodies';
import { logError } from 'features/logging/logError';
import { useAvailablePlans } from 'features/pricing/hook/useAvailablePlans';
import useSubscriptionState from 'features/pricing/hook/useSubscriptionState';
import { ErrorBoundary } from 'features/react-error-boundary/ErrorBoundary';
import { StripeEmbeddedModal } from 'features/stripe-portal/StripeEmbeddedModal';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { paymentWasSuccessful } from 'pages/paymentCallback';
import { ReactElement, useState } from 'react';
import ReactModal from 'react-modal';
import { useLocation } from 'react-router';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useAppThemeStore } from 'styles/new-theme/useAppThemeStore';
function trialModalClosed(key: string) {
  localStorage.setItem(key, new Date().valueOf().toString());
}

function trialModalHasBeenClosedLessThanXHoursAgo(key: string, x: number): boolean {
  const closedAt = localStorage.getItem(key);
  if (!closedAt) {
    return false;
  }
  const closedAtMS = parseInt(closedAt);
  const now = new Date().valueOf();
  const xHoursAgo = now - x * 60 * 60 * 1000;

  return closedAtMS > xHoursAgo;
}

const checkSubscriptionSetToFreeStoreageItem = (storageKey: string, customerId: number | null) => {
  if (!customerId) {
    return false;
  }
  return localStorage.getItem(storageKey) !== null;
};

export const trialModalExcludedPages = [
  '/onboarding',
  '/login',
  '/free',
  '/payment-success',
  '/payment-callback',
  '/register',
  '/reset-password'
];

export const TrialModal = (): ReactElement | null => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('lg'));
  const { showModal } = useModal();
  const themeMode = useAppThemeStore(state => state.themeMode);
  const { currentPricingPlan, currentSubscriptionState } = useSubscriptionState();
  const customerId = useAppSelector(state => state.customer.id);
  const enforceFreeTrial = useAppSelector(state => state.customer.enforceFreeTrial);
  const trialUsed = useAppSelector(state => state.customer.trialUsed);
  const preferredPricingId = useAppSelector(state => state.customer.preferredPricingId);
  const [modalClosed, setModalClosed] = useState<boolean>(false);
  const { data } = useAvailablePlans({ period: 'month' });
  const { starterPlan, proPlan } = data ?? {};
  const location = useLocation();
  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();
  const modalClosedAtKey = `trial_modal_closed_at-${customerId}`;
  const subscriptionSetToFreeKey = LocalStorageKey.ShouldShowUserIsNowOnFree + customerId;
  const subscriptionSetToFree = checkSubscriptionSetToFreeStoreageItem(subscriptionSetToFreeKey, customerId);

  const primaryAction = () => {
    if (!proPlan) {
      logError('proPlan is undefined', 'react', {
        proPlan: proPlan
      });
    } else {
      showModal('STRIPE_EMBEDDED_PAYMENT', {
        size: 1100,
        pricingId: proPlan.id
      });
    }
  };

  const secondaryAction = () => {
    if (enforceFreeTrial) {
      if (!starterPlan) {
        logError('starterPlan is undefined', 'react', {
          starterPlan: starterPlan
        });
      } else {
        showModal('STRIPE_EMBEDDED_PAYMENT', {
          size: 1100,
          pricingId: starterPlan.id
        });
      }
    } else if (enforceFreeTrial === false || enforceFreeTrial === 0) {
      setModalClosed(true);
      trialModalClosed(modalClosedAtKey);
    } else {
      logError('enforceFreeTrial is neither true nor false', 'react', {
        enforceFreeTrial: enforceFreeTrial
      });
    }
  };

  //hide this modal when customer is not authenticated
  if (!customerId) {
    return null;
  }

  //prevent race condition if the payment is not processed fast enough
  if (paymentWasSuccessful()) {
    return null;
  }

  //hide this modal on onboarding and login pages
  if (trialModalExcludedPages.findIndex(path => location.pathname.startsWith(path)) > -1) {
    return null;
  }

  //while we are still loading the subscription state, no modal should be displayed
  if (!currentSubscriptionState) {
    return null;
  }

  // if the customer already has a paid subscription, we don't need to ask him to create one
  if (
    currentPricingPlan.type !== 'free' &&
    (currentSubscriptionState?.status === 'subscribed' ||
      currentSubscriptionState?.status === 'grace' ||
      currentSubscriptionState?.status === 'on trial' ||
      currentSubscriptionState?.status === 'on trial grace')
  ) {
    return null;
  }

  //if the trial has already been used, we don't want to offer it again
  if (trialUsed && !subscriptionSetToFree) {
    return null;
  }

  //if the optional modal has already been closed before, the customer does not see it again for a certain timeframe
  if (!enforceFreeTrial && trialModalHasBeenClosedLessThanXHoursAgo(modalClosedAtKey, 48) && !subscriptionSetToFree) {
    return null;
  }

  let modalBody;
  //redirect directly to stripe if the customer has a specific pricingId
  if (preferredPricingId) {
    modalBody = <StripeEmbeddedModal pricingId={preferredPricingId} closeModal={() => undefined} />;
  } else if (subscriptionSetToFree){
    modalBody = <TrialEndedModalBody primaryAction={showUpgradeSubscriptionModal} secondaryAction={() => {
      localStorage.removeItem(subscriptionSetToFreeKey);
      setModalClosed(true);
    }} />
  } else {
    modalBody = <TrialModalBody enforceFreeTrial={enforceFreeTrial} primaryAction={primaryAction} secondaryAction={secondaryAction}/>
  }
  
  return (
    <ReactModal
      isOpen={!modalClosed}
      bodyOpenClassName="modal-open"
      overlayClassName={cx('modal fade', 'show')}
      className={cx('modal-dialog', isSmallDevice ? '' : 'modal-1100')}
      shouldCloseOnOverlayClick={!enforceFreeTrial}
      shouldCloseOnEsc={!enforceFreeTrial}
      shouldFocusAfterRender={true}
      onRequestClose={() => setModalClosed(!enforceFreeTrial)}
    >
      <ErrorBoundary>
        <div className={cx('modal-content', themeMode)}>
          <ModalWrapper>{modalBody}</ModalWrapper>
        </div>
      </ErrorBoundary>
    </ReactModal>
  );
};

const ModalWrapper = styled.div`
  z-index: 1;
`;
